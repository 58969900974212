<template>
  <div class="goods">
    <van-row justify="center" class="chosen">
      <van-col
        span="12"
        v-for="item in shop"
        :key="item.id"
        class="r_item"
        @click="goto(item.id)"
      >
        <div class="img-div"><img :src="item.thumb" /></div>
        <van-cell :title="item.title" :value="item.price" />
      </van-col>

      <van-col v-if="shop.length % 2" span="12"></van-col>
    </van-row>

    <siteabbar :idx="0" />
  </div>
</template>

<script>
import Siteabbar from "../components/Sitetabbar";
import { mapState } from "vuex";
import { getBarCate } from "@/api/query";
import { useRoute } from "vue-router";
import { reactive, onMounted, toRefs } from "vue";
import { Row, Col, Image } from "vant";

export default {
  components: {
    [Siteabbar.name]: Siteabbar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
  },
  setup() {
    const state = reactive({
      shop: [],
    });
    const route = useRoute();
    onMounted(async () => {
      const { catid } = route.query;
      const { data } = await getBarCate(catid);
      state.shop = data;
    });
    return {
      ...toRefs(state),
    };
  },
  computed: {
    ...mapState("cart", ["cart"]),
  },

  methods: {
    formatPrice() {
      return "¥" + (this.goods.price / 100).toFixed(2);
    },

    goto(id) {
      this.$router.push("/goods?id=" + id);
    },
  },
};
</script>

<style lang="less" scoped>
.goods {
  padding-bottom: 50px;
  justify-content: flex-start;

  .img-div {
    overflow: hidden;
  }
  .chosen .r_item {
    padding: 0 10px;
  }
  .chosen .img-div {
    height: 30vw;
  }
  .img-div img {
    width: 100%;
    height: auto;
  }

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
  .goods-swipe img {
    max-height: 500px;
  }
}
</style>
